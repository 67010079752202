import { Box, makeStyles, Typography } from '@material-ui/core';
import React, { useContext, useEffect, useState } from 'react';
import { firebaseAnalytics } from '../../firebase';
import mark from '../../assets/images/mark.png';
import logo from '../../assets/images/logo.png';
import SelectBox from '../../Components/SelectBox';
import ButtonApp from '../../Components/ButtonApp';
import RefferCode from '../../Components/RefferCode';
import useInput from '../../hooks/useInput';
import useGetHelpData from '../../hooks/useGetHelpData';
import { Context } from '../../Context';
import Splash from './Splash';
import { useHistory } from 'react-router';

const useStyles = makeStyles(() => ({
  img: {
    width: '100%',
    maxHeight: 330,
    objectFit: 'cover',
  },

  logo: {
    width: 200,
    height: 70,
    objectFit: 'contain',
  },
  refferText: {
    fontSize: 12,
    color: '#6F7782',
    fontFamily: 'App-font-medium',
  },
  button: {
    textTransform: 'capitalize',
    color: '#fff',
    boxShadow: 'unset',
    borderRadius: 120,
    width: '100%',
    '& .MuiButton-label': {
      fontSize: 14,
      fontFamily: 'App-semi-bold',
    },
    marginTop: 40,
    height: 42,
    marginBottom: 20,
  },
}));

const Home = () => {
  const classes = useStyles();
  const [showRefferCode, setShowRefferCode] = useState(false);
  const [accountType, setAccountType] = useState(null);
  const [showSplash, setShowSplash] = useState(true);
  const refferCode = useInput('');
  const { setDesktopImg, isClaro, setIsHome } = useContext(Context);
  const helpData = useGetHelpData(null, null, true);
  const history = useHistory();

  useEffect(() => {
    if (helpData?.desktop_img) {
      setDesktopImg(helpData?.desktop_img);
    }
  }, [helpData?.desktop_img]);

  useEffect(() => {
    setIsHome(true);
    firebaseAnalytics.logEvent('home-page');
  }, []);

  const handleContinue = () => {
    if (accountType) {
      history.push('/signup', {
        accountType,
        referral_code: refferCode.value,
      });
    }
  };

  const hideSplash = () => {
    setShowSplash(false);
    setIsHome(false);
  }

  if (showSplash) {
    return <Splash setShowSplash={hideSplash} />;
  }

  return (
    <Box minHeight="100vh">
      <img src={mark} alt="mark" className={classes.img} />
      <Box display="flex" justifyContent="center" alignItems="center">
        <img src={logo} alt="logo" className={classes.logo} />
      </Box>
      <Box padding="0 25px" marginTop="50px" paddingBottom="30px">
        <Typography
          color="textPrimary"
          style={{ fontSize: 14, marginBottom: 11 }}
        >
          Cuéntenos con qué perfil de negocio quiere empezar a cobrar con
          tarjeta.
        </Typography>

        <SelectBox
          options={['Persona Natural', 'Persona Jurídica']}
          outlined
          value={accountType}
          onChange={(e) => setAccountType(e.target.value)}
        />

        <RefferCode
          handleRefferCode={() => setShowRefferCode(!showRefferCode)}
          showRefferCode={showRefferCode}
          refferCode={refferCode.value}
          onChangeRefferCode={refferCode.onChange}
        />

        <ButtonApp
          text="Continuar"
          onClick={handleContinue}
          marginTop={30}
          disabled={!accountType || accountType === '0'}
        />
        <Box marginTop="30px" textAlign="center">
          <Typography
            color="textPrimary"
            style={{ fontSize: 12, marginBottom: 8 }}
          >
            Quiere retomar el proceso de contratación?
          </Typography>
          <Typography
            style={{
              fontSize: 12,
              fontFamily: 'App-semi-bold',
              color: '#00e39c',
              cursor: 'pointer',
            }}
            onClick={() => history.push('/otp')}
          >
            Ingrese aquí su número de celular
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};

export default Home;
