import { Button, makeStyles } from '@material-ui/core';
import React from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';

const useStyles = makeStyles(() => ({
  button: {
    textTransform: 'capitalize',
    color: '#fff',
    background: '#5d62f1 !important',
    boxShadow: '0 3px 6px #66788033',
    borderRadius: 120,
    width: '100%',
    '& .MuiButton-label': {
      fontSize: 14,
      fontFamily: 'App-semi-bold',
    },
    height: 42,
  },

  buttonOutlined: {
    textTransform: 'capitalize',
    color: '#5d62f1',
    borderWidth: 2,
    borderRadius: 120,
    border: '1px solid #5d62f1',
    background: 'transparent !important',
    width: '100%',
    '& .MuiButton-label': {
      fontSize: 14,
      fontFamily: 'App-semi-bold',
    },

    height: 42,
  },
}));

const ButtonApp = ({
  text,
  onClick,
  color = 'primary',
  variant = 'contained',
  disabled,
  marginTop = 0,
  marginBottom = 25,
  loading,
  label,
}) => {
  const classes = useStyles();
  return (
    <Button
      variant={variant}
      color={color}
      disabled={disabled}
      className={
        variant === 'contained' ? classes.button : classes.buttonOutlined
      }
      onClick={loading ? () => null : onClick}
      style={{ marginTop, marginBottom }}
    >
      {loading ? (
        <CircularProgress style={{ color: '#fff' }} size="20px" />
      ) : (
        text || label
      )}
    </Button>
  );
};

export default ButtonApp;
